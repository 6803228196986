var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"copilot-conversations pa-0",attrs:{"fluid":""}},[_c('ERow',[_c('ECol',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ReportTable',{attrs:{"name":"conversations","sort-by":"startDate","headers":_vm.headers,"provider":_vm.fetchConversations,"force-snake-case":false,"force-url-update":"","separate-sort-params":"","filter-fields":_vm.copilotFilterFields},on:{"change":function($event){_vm.conversations = $event},"click:row":_vm.viewConversation},scopedSlots:_vm._u([{key:"item.startDate",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.formatDate(item.startDate))+"\n        ")]}},{key:"item.endDate",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.formatDate(item.endDate))+"\n        ")]}},{key:"item.messagesCount",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v("\n            "+_vm._s(item.messagesCount)+"\n          ")])]}},{key:"item.feedbackCount",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v("\n            "+_vm._s(item.feedbackCount)+"\n          ")])]}},{key:"item.inputTokensCount",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center",style:({
              color: _vm.getColor(item.inputTokensCount, 'inputTokensCount'),
            })},[_vm._v("\n            "+_vm._s(item.inputTokensCount)+"\n          ")])]}},{key:"item.outputTokensCount",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center",style:({
              color: _vm.getColor(item.outputTokensCount, 'outputTokensCount'),
            })},[_vm._v("\n            "+_vm._s(item.outputTokensCount)+"\n          ")])]}},{key:"item.costEstimation",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-start align-center"},[(Number(item.costEstimation).toFixed(4) > 0)?_c('span',{style:({
                color: _vm.getColor(item.costEstimation, 'costEstimation'),
              })},[_vm._v("\n              "+_vm._s(Number(item.costEstimation).toFixed(4) + "$")+"\n            ")]):_c('span',[_vm._v(" - ")])])]}},{key:"item.duration",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v("\n            "+_vm._s(_vm.getConversationDuration(item))+"\n          ")])]}},{key:"item.cameraExid",fn:function({ item }){return [(item.cameraExid)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.cameraDialogStore.openDialog({ camera: item.cameraExid })}}},[_vm._v(_vm._s(item.cameraExid)+"\n            ")])]):_c('span',[_vm._v("-")])]}},{key:"item.projectExid",fn:function({ item }){return [(item.projectExid)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.projectStore.openDialog(item.projectExid)}}},[_vm._v("\n              "+_vm._s(item.projectExid)+"\n            ")])]):_c('span',[_vm._v("-")])]}},{key:"item.user",fn:function({ item }){return [_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.openUserDialog(item.user)}}},[_vm._v("\n            "+_vm._s(item.user)+"\n          ")])]}},{key:"item.model",fn:function({ item }){return [_vm._v("\n          "+_vm._s(item.model)+"\n        ")]}},{key:"item.context",fn:function({ item, header }){return [_c('div',{attrs:{"title":item.context}},[_c('ETruncatedDiv',{attrs:{"width":header.width ?? 350}},[_vm._v(_vm._s(item.context)+"\n            ")])],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }