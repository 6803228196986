<template>
  <v-container fluid class="copilot-conversations pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="conversations"
          sort-by="startDate"
          :headers="headers"
          :provider="fetchConversations"
          :force-snake-case="false"
          force-url-update
          separate-sort-params
          :filter-fields="copilotFilterFields"
          @change="conversations = $event"
          @click:row="viewConversation"
        >
          <template #item.startDate="{ item }">
            {{ formatDate(item.startDate) }}
          </template>

          <template #item.endDate="{ item }">
            {{ formatDate(item.endDate) }}
          </template>

          <template #item.messagesCount="{ item }">
            <div class="d-flex justify-center align-center">
              {{ item.messagesCount }}
            </div>
          </template>

          <template #item.feedbackCount="{ item }">
            <div class="d-flex justify-center align-center">
              {{ item.feedbackCount }}
            </div>
          </template>

          <template #item.inputTokensCount="{ item }">
            <div
              class="d-flex justify-center align-center"
              :style="{
                color: getColor(item.inputTokensCount, 'inputTokensCount'),
              }"
            >
              {{ item.inputTokensCount }}
            </div>
          </template>

          <template #item.outputTokensCount="{ item }">
            <div
              class="d-flex justify-center align-center"
              :style="{
                color: getColor(item.outputTokensCount, 'outputTokensCount'),
              }"
            >
              {{ item.outputTokensCount }}
            </div>
          </template>

          <template #item.costEstimation="{ item }">
            <div class="d-flex justify-start align-center">
              <span
                v-if="Number(item.costEstimation).toFixed(4) > 0"
                :style="{
                  color: getColor(item.costEstimation, 'costEstimation'),
                }"
              >
                {{ Number(item.costEstimation).toFixed(4) + "&dollar;" }}
              </span>
              <span v-else> - </span>
            </div>
          </template>

          <template #item.duration="{ item }">
            <div class="d-flex justify-center align-center">
              {{ getConversationDuration(item) }}
            </div>
          </template>

          <template #item.cameraExid="{ item }">
            <div v-if="item.cameraExid" class="d-flex align-center">
              <span
                class="cursor-pointer primary--text"
                @click.stop="
                  cameraDialogStore.openDialog({ camera: item.cameraExid })
                "
                >{{ item.cameraExid }}
              </span>
            </div>
            <span v-else>-</span>
          </template>

          <template #item.projectExid="{ item }">
            <div v-if="item.projectExid" class="d-flex align-center">
              <span
                class="cursor-pointer primary--text"
                @click.stop="projectStore.openDialog(item.projectExid)"
              >
                {{ item.projectExid }}
              </span>
            </div>
            <span v-else>-</span>
          </template>

          <template #item.user="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click.stop="openUserDialog(item.user)"
            >
              {{ item.user }}
            </span>
          </template>

          <template #item.model="{ item }">
            {{ item.model }}
          </template>

          <template #item.context="{ item, header }">
            <div :title="item.context">
              <ETruncatedDiv :width="header.width ?? 350"
                >{{ item.context }}
              </ETruncatedDiv>
            </div>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import ReportTable from "@/components/ReportTable"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"
import { useUserStore } from "@/stores/users"
import * as d3 from "d3"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.COPILOT.VIEW],
  },
  name: "CopilotMessagesHistory",
  components: {
    ReportTable,
  },
  data() {
    return {
      headers: [
        {
          text: "User",
          value: "user",
          sortable: true,
          filterable: true,
          width: 200,
        },
        { text: "Project", value: "projectExid", sortable: true, width: 90 },
        { text: "Camera", value: "cameraExid", sortable: true, width: 90 },
        { text: "Messages", value: "messagesCount", sortable: true, width: 70 },
        { text: "Duration", value: "duration", sortable: false },
        { text: "Model", value: "model", sortable: true, width: 85 },
        {
          text: "Input tokens",
          value: "inputTokensCount",
          sortable: true,
          width: 70,
        },
        {
          text: "Output tokens",
          value: "outputTokensCount",
          sortable: true,
          width: 70,
        },
        {
          text: "Estimated Cost",
          value: "costEstimation",
          sortable: true,
          width: 80,
        },
        { text: "Start Date", value: "startDate", sortable: true, width: 150 },
        { text: "End Date", value: "endDate", sortable: true, width: 150 },
        { text: "Feedback", value: "feedbackCount", sortable: true, width: 70 },
        { text: "Context", value: "context", sortable: true },
      ],
      copilotFilterFields: {
        user: {
          component: "TextFieldSearchFilter",
        },
      },
      conversations: [],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProjectStore),

    minMaxValues() {
      const initialStats = {
        inputTokensCount: { min: Infinity, max: -Infinity },
        outputTokensCount: { min: Infinity, max: -Infinity },
        costEstimation: { min: Infinity, max: -Infinity },
      }

      return this.conversations.reduce((acc, item) => {
        if (item.inputTokensCount !== undefined) {
          acc.inputTokensCount.min = Math.min(
            acc.inputTokensCount.min,
            item.inputTokensCount
          )
          acc.inputTokensCount.max = Math.max(
            acc.inputTokensCount.max,
            item.inputTokensCount
          )
        }
        if (item.outputTokensCount !== undefined) {
          acc.outputTokensCount.min = Math.min(
            acc.outputTokensCount.min,
            item.outputTokensCount
          )
          acc.outputTokensCount.max = Math.max(
            acc.outputTokensCount.max,
            item.outputTokensCount
          )
        }
        if (item.costEstimation !== undefined) {
          acc.costEstimation.min = Math.min(
            acc.costEstimation.min,
            item.costEstimation
          )
          acc.costEstimation.max = Math.max(
            acc.costEstimation.max,
            item.costEstimation
          )
        }

        return acc
      }, initialStats)
    },
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    async fetchConversations({ params }) {
      try {
        return await EvercamLabsApi.copilot.getAllConversations(params)
      } catch (error) {
        console.error("Failed to fetch conversations:", error)

        return {
          items: [],
          total: 0,
        }
      }
    },
    async viewConversation(conversation) {
      await this.$router.push(`copilot/conversations/${conversation.id}`)
    },
    formatDate(date) {
      return this.$moment(date).format("YYYY-MM-DD HH:mm:ss")
    },
    getConversationDuration(conversation) {
      const start = this.$moment(conversation.startDate)
      const end = this.$moment(conversation.endDate)
      const duration = this.$moment.duration(end.diff(start))
      const minutes = duration.minutes()
      const seconds = duration.seconds()

      if (minutes >= 1) {
        return `${minutes}m ${seconds}s`
      } else {
        return `${seconds}s`
      }
    },
    getColor(value, type) {
      if (value === undefined) {
        return
      }
      const { min, max } = this.minMaxValues[type]

      const colorScale = d3
        .scaleLinear()
        .domain([min, (min + max) / 2, max])
        .range(["rgb(75,147,15)", "rgb(231,38,38)"])
        .interpolate(d3.interpolateRgb)

      return colorScale(value)
    },
  },
}
</script>

<style lang="scss">
.copilot-conversations {
  .custom-data-table {
    tbody tr {
      cursor: pointer;
    }
  }
}
</style>
